import networkClient from '../utils/networkClient';

export const isValidToken = async token => {
  return networkClient.post(
    `/transactions/business/is-valid`,
    { token },
    {
      headers: {
        'x-auth-pay': process.env.REACT_APP_AUTH_PAY_KEY, // data.token
      },
    },
  );
};
