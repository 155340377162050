import React from 'react';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';

import messages from '../../messages';
import {
  flattenMessages,
  getLocaleFromLocalStorage,
  changeDocumentDirection,
} from './utils';

const initialLocale = 'ar';
const locale = getLocaleFromLocalStorage();
export const cache = createIntlCache();
/** You can use this variable in other files even after reassigning it. */
// eslint-disable-next-line import/no-mutable-exports
export let intl = createIntl(
  { locale: initialLocale, messages: flattenMessages(messages[locale]) },
  cache,
);
// eslint-disable-next-line import/no-mutable-exports
export let fmt = intl.formatMessage;
// eslint-disable-next-line import/no-mutable-exports
export let fmtHTML = intl.formatHTMLMessage;

class IntlWrapper extends React.Component {
  componentDidMount() {
    const lang = getLocaleFromLocalStorage();
    document.documentElement.lang = lang;
    changeDocumentDirection(lang);
  }

  render() {
    const { children } = this.props;
    const lang = getLocaleFromLocalStorage();

    intl = createIntl(
      { locale, messages: flattenMessages(messages[lang]) },
      cache,
    );

    fmt = intl.formatMessage;

    fmtHTML = intl.formatHTMLMessage;

    return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
  }
}

export default IntlWrapper;
