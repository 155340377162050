import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import LocaleWrapper from './components/LocaleWrapper/index';

ReactDOM.render(
  <LocaleWrapper>
    <App />
  </LocaleWrapper>,
  document.getElementById('root'),
);
