/* eslint-disable jsx-a11y/label-has-associated-control,no-useless-escape */
import React, { Component } from 'react';
import swalWithReact from '@sweetalert/with-react';
import '../PaymentCard/styles.scss';
import './styles.scss';
import DOMPurify from 'dompurify';
import ToggleSwitch from '../../Toggle/Toggle';
import { fmt } from '../../LocaleWrapper/index';
import { getQueryParam } from '../../../utils/utils';
import {
  confirmQiCard,
  confirmQiInstallment,
  proceedPayment,
  proceedPaymentInstallment,
  verifyQiCard,
  verifyQiInstallment,
} from '../../../services/merchant';
import { cardTypes } from '../../../constants';
import {
  getLocaleFromLocalStorage,
  LOCALE,
  setLocaleInLocalStorage,
} from '../../LocaleWrapper/utils';

const { sanitize } = DOMPurify;
const javascriptPurify = dirty => dirty.replace(/^(javascript\:)/, '');

class CForm extends Component {
  timeoutId = 0;

  constructor(props) {
    super(props);
    const currentYear = new Date().getFullYear();
    // const { merchantData } = props;
    // const { isMasterCardAllowed } = merchantData;
    this.state = {
      currentLocale: getLocaleFromLocalStorage(),
      loading: false,
      payNowText: fmt({ id: 'payment_form.pay_now' }),
      cancel: fmt({ id: 'payment_form.cancel' }),
      wait: fmt({ id: 'common.wait' }),
      fields: {
        name: '',
        email: '',
        message: '',
      },
      // isMasterCardAllowed,
      isQiVerified: false,
      otp: '',
      showOtherCardInfo: true, // isMasterCardAllowed,
      cardNumber: '',
      message2: '',
      isQiCardNumberValid: false,
      isCreditCardNumber: false,
      isCreditCardCVVNumber: false,
      isCreditCardName: false,
      isQiCardName: false,
      cardCvv: '',
      creditCardNumberValidationMessage: '',
      creditCardNameValidationMessage: '',
      isCreditCardDateValid: '',
      creditCardCVVValidationMessage: '',
      cardMonth: '',
      cardHolder: '',
      cardYear: '',
      monthsArr: new Array(12).fill(0).map((el, index) => index + 1),
      yearsArr: Array.from(new Array(9), (x, i) => currentYear + i),
      cardType: cardTypes.MASTER_CARD,
      installments: false,
    };
  }

  componentDidMount() {}

  /**
   * handle month and year validation message
   */
  handleMonthYearValidationMessage = () => {
    const { cardYear, cardMonth } = this.state;
    const today = new Date();
    const currentYear = Number(new Date().getFullYear());
    const currentMonth = Number(today.getMonth()) + 1;
    if (currentYear === Number(cardYear)) {
      if (currentMonth >= Number(cardMonth)) {
        this.setState({ isCreditCardDateValid: false });
      }
      if (currentMonth < Number(cardMonth)) {
        this.setState({ isCreditCardDateValid: true });
        this.setState({
          message2: fmt({ id: 'payment_form.errors.expired_card' }),
        });
      }
    } else if (currentYear < Number(cardYear)) {
      this.setState({ isCreditCardDateValid: true });
      this.setState({
        message2: fmt({ id: 'payment_form.errors.expired_card' }),
      });
    } else if (currentYear > Number(cardYear)) {
      this.setState({ isCreditCardDateValid: false });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
  };

  /**
   * call update state for parent component
   * @param name
   * @param value
   */
  updateMainState = (name, value) => {
    const { onUpdateStateValue } = this.props;
    onUpdateStateValue({
      name,
      value,
    });
  };

  /**
   * check and validate Qi card cvv number
   */
  checkQiCardCVVNumber = () => {
    const { cardCvv /* language */ } = this.state;
    if (cardCvv.trim().length > 0 && cardCvv.trim().length > 2) {
      // this.setState({ isQiCardCVVValid: true });
      /* this.setState({
        qiCardCVVValidationMessage: 'qiCardCVVValidationMessage',
      }); */
    } else {
      // this.setState({ isQiCardCVVValid: false });
      // if (language === 'AR') {
      /* this.setState({
        qiCardCVVValidationMessage: 'Please enter correct number',
      }); */
      // } else {
      /* this.setState({
        qiCardCVVValidationMessage: 'Please enter correct number',
      }); */
      // }
    }
  };

  /**
   * check and validate payment card cvv number
   */
  checkCreditCardCvvNumber = () => {
    const { cardCvv } = this.state;
    if (cardCvv.trim().length === 3) {
      this.setState({ isCreditCardCVVNumber: true });
      this.setState({ creditCardCVVValidationMessage: '' });
    } else {
      this.setState({ isCreditCardCVVNumber: false });
      this.setState({
        creditCardCVVValidationMessage: fmt({
          id: 'payment_form.errors.card_cvv_validation_message',
        }),
      });
    }
  };

  /**
   * check and validate payment card holder name
   */
  checkCardName = () => {
    const { cardHolder, cardType } = this.state;
    if (cardHolder.trim().length !== 0) {
      const newState =
        cardType === cardTypes.MASTER_CARD
          ? {
              isCreditCardName: true,
              creditCardNameValidationMessage: fmt({
                id: 'payment_form.errors.credit_card_validation_message',
              }),
            }
          : {
              isQiCardName: true,
              qiCardNameValidationMessage: fmt({
                id: 'payment_form.errors.credit_card_validation_message',
              }),
            };
      this.setState({ ...newState });
    } else {
      const newState =
        cardType === cardTypes.MASTER_CARD
          ? {
              isCreditCardName: false,
              creditCardNameValidationMessage: fmt({
                id: 'payment_form.errors.card_holder_validation_message',
              }),
            }
          : {
              isQiCardName: false,
              qiCardNameValidationMessage: fmt({
                id: 'payment_form.errors.card_holder_validation_message',
              }),
            };
      this.setState({ ...newState });
    }
  };

  /**
   * check and validate Qi card number
   */
  checkQiCardNumber = () => {
    const { cardNumber } = this.state;
    this.setState({
      // qiCardValidationMessage: fmt({
      //   id: 'payment_form.errors.card_number_validation_message',
      // }),
    });

    if (cardNumber.trim().length !== 0) {
      if (cardNumber.trim().length > 18) {
        if (!cardNumber.match(/633/) && cardNumber.length > 18) {
          this.setState({ isQiCardNumberValid: false });
          this.setState({
            // qiCardValidationMessage: fmt({
            //   id: 'payment_form.errors.card_number_validation_message',
            // }),
          });
        }

        if (cardNumber.length > 16) {
          this.setState({ isQiCardNumberValid: false });
          this.setState({
            // qiCardValidationMessage: fmt({
            //   id: 'payment_form.errors.card_number_numbers_validation_message',
            // }),
          });
        }

        if (!cardNumber.match(/633/)) {
          this.setState({
            isQiCardNumberValid: false,
            // qiCardValidationMessage: fmt({
            //   id: 'payment_form.errors.card_number_validation_message',
            // }),
          });
        }
        if (cardNumber.match(/633/) && cardNumber.length < 20) {
          this.setState({ isQiCardNumberValid: true });
        }
      }
    } else {
      this.setState({ isQiCardNumberValid: false });
      this.setState({
        // qiCardValidationMessage: fmt({
        //   id: 'payment_form.errors.card_number_validation_message',
        // }),
      });
    }
  };

  /**
   * validate and check card number
   */
  checkCardNumber = () => {
    const { cardNumber } = this.state;
    if (cardNumber?.length > 0) {
      if (cardNumber.trim().length > 18) {
        if (
          cardNumber.match(/2[1-9]/) &&
          !cardNumber.match(/5[1-5]/) &&
          !cardNumber.match(/4/) &&
          !cardNumber.match(/34/) &&
          cardNumber.length > 18
        ) {
          this.setState({ isCreditCardNumber: false });
          this.setState({
            creditCardNumberValidationMessage: fmt({
              id: 'payment_form.errors.card_number_validation_message',
            }),
          });
        }

        if (cardNumber.length > 18) {
          this.setState({ isCreditCardNumber: false });
          this.setState({
            creditCardNumberValidationMessage: fmt({
              id: 'payment_form.errors.card_number_validation_message',
            }),
          });
        }

        if (
          cardNumber.match(/2[1-9]/) &&
          !cardNumber.match(/5[1-5]/) &&
          !cardNumber.match(/4/) &&
          !cardNumber.match(/34/) &&
          !cardNumber.match(/633/)
        ) {
          this.setState({ isCreditCardNumber: false });
          this.setState({
            creditCardNumberValidationMessage: fmt({
              id: 'payment_form.errors.card_number_validation_message',
            }),
          });
        }

        if (cardNumber.match(/633/)) {
          this.setState({
            isCreditCardNumber: false,
            creditCardNumberValidationMessage: fmt({
              id: 'payment_form.errors.card_number_qi_validation_message',
            }),
          });
        }
        if (
          (cardNumber.match(/2[1-9]/) ||
            cardNumber.match(/5[1-5]/) ||
            cardNumber.match(/4/)) &&
          cardNumber.length < 20
        ) {
          this.setState({ isCreditCardNumber: true });
          this.setState({ creditCardNumberValidationMessage: '' });
        }
      }
    } else {
      this.setState({ isCreditCardNumber: false });
      this.setState({
        creditCardNumberValidationMessage: fmt({
          id: 'payment_form.errors.card_number_validation_message',
        }),
      });
    }
  };

  /**
   * handle card holder name value change
   * @param event
   */
  handleFormChangeHolder = event => {
    const { name, value } = event.target;
    const currentValue = value.replace(/[^A-Za-z\s]/gi, '');

    this.setState({ [name]: currentValue }, () => {
      this.checkCardName();
    });
    this.updateMainState(name, currentValue);
  };

  /**
   * handle form value change and set state
   * @param event
   */
  handleFormChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      if (name === 'cardMonth' || name === 'cardYear') {
        this.handleMonthYearValidationMessage();
      }
      if (name === 'userCardType') {
        this.handleUserCardTypeValidationMessage();
      }
    });
    this.updateMainState(name, value);
  };

  handleUserCardTypeValidationMessage = () => {
    const { userCardType } = this.state;
    if (!userCardType) {
      this.setState({
        // userCardTypeIsValid: false,
        // userCardTypeValidationMessage: fmt({
        //   id: 'payment_form.errors.card_type_validation_message',
        // }),
      });
    } else {
      this.setState({
        // userCardTypeIsValid: true,
        // userCardTypeValidationMessage: '',
      });
    }
  };

  /**
   * manipulate card number with spaces
   * @param value
   * @returns {*}
   */
  manipulateCardNumber = value => {
    const newValue = value.replace(/\D/g, '');
    let cardNumber;
    if (/^3[47]\d{0,13}$/.test(newValue)) {
      cardNumber = newValue
        .replace(/(\d{4})/, '$1 ')
        .replace(/(\d{4}) (\d{6})/, '$1 $2 ');
    } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(newValue)) {
      // diner's club, 14 digits
      cardNumber = newValue
        .replace(/(\d{4})/, '$1 ')
        .replace(/(\d{4}) (\d{6})/, '$1 $2 ');
    } else if (/^\d{0,16}$/.test(newValue)) {
      // regular cc number, 16 digits
      cardNumber = newValue
        .replace(/(\d{4})/, '$1 ')
        .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
        .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
    }

    return cardNumber;
  };

  /**
   * Qi card value change event handler
   * @param event
   */
  onQiCardNumberChange = event => {
    this.checkQiCardNumber();
    const { value, name } = event.target;

    if (value.length === 2) {
      if (value !== '63') {
        this.setState({ cardNumber: '' });
        this.setState({
          // qiCardValidationMessage: fmt({
          //   id: 'payment_form.errors.qi_card_number_only_validation_message',
          // }),
        });
      }
    }
    const cardNumber = this.manipulateCardNumber(value);
    this.setState({ [name]: cardNumber.trimRight() });
    this.updateMainState(name, cardNumber);
  };

  /**
   * payment card value change event handler
   * @param event
   */
  onCardNumberChange = event => {
    this.checkCardNumber();
    const { value, name } = event.target;
    if (value.length === 2) {
      if (value === '63') {
        this.setState({ cardNumber: '' });
        this.setState({
          creditCardNumberValidationMessage: fmt({
            id: 'payment_form.errors.credit_card_in_qi_validation_message',
          }),
        });
      }
    }
    const cardNumber = this.manipulateCardNumber(value);
    this.setState({ [name]: cardNumber?.trimRight() });
    this.updateMainState(name, cardNumber);
  };

  /**
   * set isCardFlipped value to true
   */
  onCvvFocus = () => {
    this.checkCreditCardCvvNumber();
    this.updateMainState('isCardFlipped', true);
  };

  /**
   * set isCardFlipped value to false
   */
  onCvvBlur = () => {
    this.checkCreditCardCvvNumber();
    this.updateMainState('isCardFlipped', false);
  };

  /**
   * cancel payment and redirect to cancel url
   */
  cancelPayment = () => {
    const { merchantData } = this.props;
    window.location.href = sanitize(
      javascriptPurify(`${merchantData.cancelUrl}`),
    );
  };

  submitPayment = async () => {
    if (navigator.onLine) {
      const { merchantData } = this.props;
      const { cardType, isQiVerified } = this.state;
      if (cardType === cardTypes.MASTER_CARD) {
        swalWithReact({
          closeOnClickOutside: false,
          closeOnEsc: false,
          buttons: [fmt({ id: 'common.cancel' }), fmt({ id: 'common.ok' })],
          content: (
            <div>
              <p>
                {fmt(
                  { id: 'payment_form.messages.proceed_payment' },
                  {
                    val: (
                      <b style={{ color: 'red' }}>
                        {merchantData?.amount} {merchantData?.currency || 'USD'}
                      </b>
                    ),
                  },
                )}
              </p>
            </div>
          ),
          icon: 'warning',
          dangerMode: true,
        }).then(async willDelete => {
          if (willDelete) {
            await this.fetchData();
          } else {
            window.location.href = merchantData.cancelUrl;
          }
        });
      } else if (isQiVerified) {
        swalWithReact({
          closeOnClickOutside: false,
          closeOnEsc: false,
          content: (
            <div>
              <p>
                {fmt(
                  { id: 'payment_form.messages.proceed_payment' },
                  {
                    val: (
                      <b style={{ color: 'red' }}>
                        {merchantData?.amount || 5}{' '}
                        {merchantData?.currency || 'USD'}
                      </b>
                    ),
                  },
                )}
              </p>
            </div>
          ),
          icon: 'warning',
          buttons: [fmt({ id: 'common.cancel' }), fmt({ id: 'common.ok' })],
          dangerMode: true,
        }).then(async willDelete => {
          if (willDelete) {
            await this.fetchData();
          } else {
            window.location.href = merchantData.cancelUrl;
          }
        });
      } else {
        await this.fetchData();
      }
    } else {
      await swalWithReact({
        closeOnClickOutside: false,
        closeOnEsc: false,
        content: <p>{fmt({ id: 'common.no_connection' })}</p>,
        icon: 'error',
        button: fmt({ id: 'common.ok' }),
        dangerMode: true,
      });
    }
  };

  checkValidCardData = () => {
    const {
      isQiCardNumberValid,
      isCreditCardNumber,
      isCreditCardCVVNumber,
      isCreditCardName,
      isCreditCardDateValid,
      isQiCardName,
      cardType,
    } = this.state;

    if (
      cardType === cardTypes.MASTER_CARD &&
      isCreditCardNumber &&
      isCreditCardCVVNumber &&
      isCreditCardName &&
      isCreditCardDateValid
    ) {
      return true;
    }
    return !!(
      cardType === cardTypes.QI_CARD &&
      isQiCardName &&
      isQiCardNumberValid
    );
  };

  /**
   * validate on data then proceed payment
   */
  fetchData = async () => {
    if (navigator.onLine) {
      if (this.checkValidCardData()) {
        this.updateMainState('sp', 'block');

        this.setState({ loading: true });

        await this.payNow();
      } else {
        this.updateMainState('sp', 'block');
        swalWithReact({
          closeOnClickOutside: false,
          closeOnEsc: false,
          content: <p>{fmt({ id: 'payment_form.messages.missing_data' })}</p>,
          icon: 'error',
          buttons: [fmt({ id: 'common.cancel' }), fmt({ id: 'common.ok' })],
          dangerMode: true,
        }).then();
      }
    } else {
      swalWithReact({
        closeOnClickOutside: false,
        closeOnEsc: false,
        content: <p>{fmt({ id: 'common.no_connection' })}</p>,
        icon: 'error',
        button: fmt({ id: 'common.ok' }),
        dangerMode: true,
      }).then();
    }
  };

  /**
   * verify qi number
   */
  verifyQiNumber = async () => {
    const {
      cardNumber,
      cardHolder,
      installments,
      userCardType,
      cardType,
    } = this.state;
    const token = getQueryParam('authToken');
    try {
      const qiCardNumber = cardNumber.replace(/\s/g, '');
      if (qiCardNumber.length < 16) {
        throw new Error(
          fmt({ id: 'payment_form.errors.qi_card_number_validation_message' }),
        );
      }
      if (cardHolder.length === 0) {
        throw new Error(
          fmt({ id: 'payment_form.errors.qi_card_name_validation_message' }),
        );
      }
      this.setState({ loading: true });
      const verifyQiMethod = installments ? verifyQiInstallment : verifyQiCard;
      await verifyQiMethod({
        cardNumber: cardNumber.replace(/\s/g, ''),
        ...(installments
          ? { cardType: userCardType }
          : { cardType, cardHolder }),
        token,
      });
      this.setState({
        loading: false,
        isQiVerified: true,
        payNowText: fmt({ id: 'payment_form.pay_now' }),
      });
      swalWithReact({
        closeOnClickOutside: false,
        closeOnEsc: false,
        content: (
          <div>
            <b>{fmt({ id: 'payment_form.messages.otp_sent' })}</b>
          </div>
        ),
        icon: 'success',
        button: fmt({ id: 'common.ok' }),
      }).then();
    } catch (error) {
      this.setState({ loading: false });
      swalWithReact({
        closeOnClickOutside: false,
        closeOnEsc: false,
        content: (
          <div>
            <b
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  error.data?.error?.explanation || error.message || '',
                ),
              }}
            />
            <br />
            <span>{error.data?.error?.cause || ''}</span>
          </div>
        ),
        icon: 'error',
        button: fmt({ id: 'common.ok' }),
        dangerMode: true,
      }).then(() => {
        if (error.data?.failureUrl) {
          window.location.href = sanitize(
            javascriptPurify(error.data.failureUrl),
          );
        }
      });
    }
  };

  /**
   * handle failre message popup and redirection
   * @param {*} error
   */
  handleFailureMessage = error => {
    swalWithReact({
      closeOnClickOutside: false,
      closeOnEsc: false,
      content: (
        <div>
          <b>{error.data?.error?.explanation || error.message || ''}</b>
          <br />
          <span>{error.data?.error?.cause || ''}</span>
        </div>
      ),
      icon: 'error',
      button: fmt({ id: 'common.ok' }),
      dangerMode: true,
    }).then(() => {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
      if (error.data?.failureUrl) {
        window.location.href = sanitize(
          javascriptPurify(error.data.failureUrl),
        );
      }
    });
    this.timeoutId = setTimeout(() => {
      window.location.href = sanitize(javascriptPurify(error.data.failureUrl));
    }, 60 * 1000);
  };

  payWithCreditCard = async () => {
    const { showSuccess } = this.props;
    const {
      cardCvv,
      cardYear,
      cardMonth,
      cardNumber,
      cardHolder,
      cardType,
      userCardType,
      installments,
    } = this.state;
    const token = getQueryParam('authToken');

    try {
      const proceedPaymentMethod = installments
        ? proceedPaymentInstallment
        : proceedPayment;
      const response = await proceedPaymentMethod({
        card: {
          number: cardNumber.replace(/\s/g, ''),
          cvv: cardCvv,
          month: cardMonth || undefined,
          year: cardYear.slice(-2) || undefined,
          cardHolder: cardHolder || undefined,
        },
        // accountNumber: showInstallmentsOther ? accountNumber : undefined,
        ...(installments
          ? {
              cardType: userCardType,
            }
          : { cardType }),
        token,
      });
      if (response.success) {
        if (!response.data.pay) {
          // window.location.href = response.data.redirectionURL;
          showSuccess(null, response.data);
        } else {
          showSuccess(response.data.pay);
        }
      } else if (!response.success) {
        swalWithReact({
          closeOnClickOutside: false,
          closeOnEsc: false,
          icon: 'error',
          content: (
            <div>
              <b>{response.data.error.explanation}</b>
              <br />
              <span>{response.data.error.cause || ''}</span>
            </div>
          ),
          button: fmt({ id: 'common.ok' }),
          dangerMode: true,
        }).then(() => {
          if (response.data?.failureUrl) {
            window.location.href = sanitize(
              javascriptPurify(response.data.failureUrl),
            );
          }
        });
      }
    } catch (error) {
      this.setState({ loading: false });
      if (error.data?.failureUrl) {
        this.handleFailureMessage(error);
        // window.location.href = sanitize(
        //   javascriptPurify(error.data?.failureUrl),
        // );
      } else {
        swalWithReact({
          closeOnClickOutside: false,
          closeOnEsc: false,
          content: (
            <div>
              <b>{error.data?.error?.explanation || error.message || ''}</b>
              <br />
              <span>{error.data?.error?.cause || ''}</span>
            </div>
          ),
          icon: 'error',
          button: fmt({ id: 'common.ok' }),
          dangerMode: true,
        }).then(() => {
          if (error.data?.failureUrl) {
            window.location.href = sanitize(
              javascriptPurify(error.data.failureUrl),
            );
          }
        });
      }
    }
  };

  payWithQiCard = async () => {
    const { showSuccess } = this.props;
    const {
      cardNumber,
      otp,
      cardHolder,
      installments,
      // userCardType,
    } = this.state;
    const token = getQueryParam('authToken');
    const qiCardNumber = cardNumber.replace(/\s/g, '');
    try {
      if (qiCardNumber.length < 16) {
        throw new Error(
          fmt({ id: 'payment_form.errors.qi_card_number_validation_message' }),
        );
      }
      if (otp.length === 0) {
        throw new Error(
          fmt({ id: 'payment_form.errors.otp_validation_message' }),
        );
      }
      const confirmPaymentMethod = installments
        ? confirmQiInstallment
        : confirmQiCard;
      const response = await confirmPaymentMethod({
        cardNumber: cardNumber.replace(/\s/g, ''),
        // ...(installments ? { cardType: userCardType } : {}),
        token,
        otp,
      });
      if (response.success) {
        if (!response.data.pay) {
          // window.location.href = response.data.redirectionURL;
          showSuccess(null, response.data.formData, {
            cardNumber: cardNumber.replace(/\s/g, ''),
            cardHolder,
          });
        } else {
          showSuccess(response.data.pay, undefined, {
            cardNumber: cardNumber.replace(/\s/g, ''),
            cardHolder,
          });
        }
      } else if (!response.success) {
        swalWithReact({
          closeOnClickOutside: false,
          closeOnEsc: false,
          icon: 'error',
          content: (
            <div>
              <b>{response.data.error.explanation}</b>
              <br />
              <span>{response.data.error.cause || ''}</span>
            </div>
          ),
          button: fmt({ id: 'common.ok' }),
          dangerMode: true,
        }).then(() => {
          if (response.data?.failureUrl) {
            window.location.href = sanitize(
              javascriptPurify(response.data.failureUrl),
            );
          }
        });
      }
    } catch (error) {
      this.setState({ loading: false });
      swalWithReact({
        closeOnClickOutside: false,
        closeOnEsc: false,
        content: (
          <div>
            <b
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  error.data?.error?.explanation || error.message || '',
                ),
              }}
            />
            <br />
            <span>{error.data?.error?.cause || ''}</span>
          </div>
        ),
        icon: 'error',
        button: fmt({ id: 'common.ok' }),
        dangerMode: true,
      }).then(() => {
        if (error.data?.failureUrl) {
          window.location.href = sanitize(
            javascriptPurify(error.data.failureUrl),
          );
        }
      });
    }
  };

  /**
   * proceed payment process and valid on request body
   */
  payNow = async () => {
    const { cardType, isQiVerified } = this.state;
    switch (cardType) {
      case cardTypes.MASTER_CARD:
        await this.payWithCreditCard();
        break;
      case cardTypes.QI_CARD:
        if (isQiVerified) this.payWithQiCard();
        else this.verifyQiNumber();
        break;

      default:
        break;
    }
  };

  /**
   * handle data change and set state
   */
  handleChange = (field, e) => {
    const { fields } = this.state;
    fields[field] = e.target.value;
    this.setState({ fields });
  };

  /**
   * handle language switch change for localization
   * @param {boolean} val
   */
  onLangSwitchTriggered = val => {
    const currentLocale = val ? LOCALE.AR : LOCALE.EN;
    setLocaleInLocalStorage(currentLocale);
    this.setState({ currentLocale });
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  /**
   * handle change value for account number
   * @param {HTMLElementEventMap} event
   */
  handleAccountNumberChange = event => {
    const { name, value } = event.target;
    const currentValue = value.replace(/[^A-Za-z\s]/gi, '');

    this.setState({ [name]: currentValue }, () => {
      this.checkCardName();
    });
    this.updateMainState(name, currentValue);
  };

  render() {
    const { loading, cardType, currentLocale } = this.state;
    const {
      // isMasterCardAllowed,
      monthsArr,
      yearsArr,
      showOtherCardInfo,
      isQiCardNumberValid,
      creditCardNumberValidationMessage,
      isCreditCardCVVNumber,
      creditCardNameValidationMessage,
      isCreditCardDateValid,
      creditCardCVVValidationMessage,
      message2,
      isCreditCardName,
      cardNumber,
      wait,
      cancel,
      payNowText,
      isQiVerified,
    } = this.state;
    const {
      cardDateRef,
      cardCvvRef,
      onCardInputFocus,
      onCardInputBlur,
      children,
    } = this.props;

    return (
      <>
        <form className="card-form" onSubmit={this.handleSubmit} method="POST">
          <div className="card-form">
            <div id="cars" className="card-list">
              {children}
            </div>
            <div className="card-form__inner" id="card-form__inner">
              <div className="d-flex justify-content-center align-items-center direction-ltr mb-5">
                <span>عربي</span>
                <ToggleSwitch
                  ignoreDirection
                  checked={currentLocale !== LOCALE.AR}
                  onChange={this.onLangSwitchTriggered}
                />
                <span>En</span>
              </div>
              <div className="form-header">
                <div>
                  <h3>{fmt({ id: 'payment_form.header' })}</h3>
                </div>
                <div>
                  <h6>{fmt({ id: 'payment_form.sub_header' })}</h6>
                </div>
              </div>
              <div className="card-header head2 master-card">
                <div className="card-header-control">
                  {/* <input
                    type="radio"
                    name="exampleRadios"
                    checked={showOtherCardInfo}
                    className="radio"
                    value="Others"
                    alt=""
                    onChange={() => {}}
                    onClick={() => {
                      this.setState({
                        showOtherCardInfo: true,
                        // showQiCard: false,
                        // showInstallments: false,
                        installments: false,
                        cardNumber: '',
                        cardType: cardTypes.MASTER_CARD,
                        payNowText: fmt({ id: 'payment_form.pay_now' }),
                        isQiVerified: false,
                      });

                      this.updateMainState('showOtherCardInfo', true);
                      this.updateMainState('showQiCard', false);
                      this.updateMainState('isqi', false);
                      this.updateMainState('cardNumber', '');
                      this.updateMainState('cardType', cardTypes.MASTER_CARD);
                    }}
                  /> */}
                  <label className="form-check-label" htmlFor="inputy">
                    {fmt({ id: 'payment_form.payment_card' })}
                  </label>
                </div>
              </div>

              <div style={showOtherCardInfo ? {} : { display: 'none' }}>
                <div>
                  <div className="card-input">
                    <label htmlFor="cardNumber" className="card-input__label">
                      {fmt({ id: 'payment_form.card_number' })}
                    </label>
                    <input
                      id="cardnumber"
                      onKeyUp={this.checkCardNumber}
                      onKeyPress={this.checkCardNumber}
                      onKeyDown={this.checkCardNumber}
                      onMouseUp={this.checkCardNumber}
                      onMouseDown={this.checkCardNumber}
                      onMouseLeave={this.checkCardNumber}
                      onFocus={e => onCardInputFocus(e, 'cardNumber')}
                      onBlur={this.checkCardNumber}
                      type="tel"
                      name="cardNumber"
                      className="card-input__input"
                      autoComplete="off"
                      onChange={this.onCardNumberChange}
                      onInput={this.handleChange.bind(this, 'cardNumber')}
                      value={cardNumber}
                    />
                    {!isQiCardNumberValid ? (
                      <span style={{ color: 'red', fontWeight: 'bold' }}>
                        {creditCardNumberValidationMessage}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="card-input">
                    <label htmlFor="cardName" className="card-input__label">
                      {fmt({ id: 'payment_form.name_on_card' })}
                    </label>

                    <input
                      onMouseUp={this.checkCardName}
                      onMouseDown={this.checkCardName}
                      onMouseLeave={this.checkCardName}
                      id="cardname"
                      className="card-input__input force-ltr__input"
                      autoComplete="off"
                      name="cardHolder"
                      onChange={this.handleFormChangeHolder}
                      onFocus={e => onCardInputFocus(e, 'cardHolder')}
                    />
                    {!isCreditCardName ? (
                      <span style={{ color: 'red', fontWeight: 'bold' }}>
                        {creditCardNameValidationMessage}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="card-form__row">
                    <div className="card-form__col">
                      <div className="card-form__group">
                        <label
                          htmlFor="cardMonth"
                          className="card-input__label"
                        >
                          {fmt({ id: 'payment_form.expiration_date' })}
                        </label>
                        <select
                          className="card-input__input -select"
                          // value={cardMonth}
                          defaultValue=""
                          name="cardMonth"
                          onChange={this.handleFormChange}
                          ref={cardDateRef}
                          onMouseUp={this.handleMonthYearValidationMessage}
                          onMouseDown={this.handleMonthYearValidationMessage}
                          onMouseLeave={this.handleMonthYearValidationMessage}
                          onFocus={e => onCardInputFocus(e, 'cardDate')}
                          onBlur={onCardInputBlur}
                        >
                          <option value="" disabled>
                            {fmt({ id: 'payment_form.month' })}
                          </option>

                          {monthsArr.map(val => (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          ))}
                        </select>
                        <select
                          name="cardYear"
                          onMouseUp={this.handleMonthYearValidationMessage}
                          onMouseDown={this.handleMonthYearValidationMessage}
                          onMouseLeave={this.handleMonthYearValidationMessage}
                          className="card-input__input -select"
                          // value={cardYear}
                          defaultValue=""
                          onChange={this.handleFormChange}
                          onFocus={e => onCardInputFocus(e, 'cardDate')}
                          onBlur={onCardInputBlur}
                        >
                          <option value="" disabled>
                            {fmt({ id: 'payment_form.year' })}
                          </option>

                          {yearsArr.map(val => (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          ))}
                        </select>
                        {!isCreditCardDateValid ? (
                          <span
                            style={{
                              color: 'red',
                              fontWeight: 'bold',
                            }}
                          >
                            {message2}
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    <div className="card-form__col -cvv">
                      <div className="card-input">
                        <div
                          style={{
                            flex: 1,
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <label
                            htmlFor="cardCvv"
                            className="card-input__label"
                          >
                            {fmt({ id: 'payment_form.cvv' })}
                          </label>
                        </div>

                        <input
                          id="cvv"
                          onMouseUp={this.checkCreditCardCvvNumber}
                          onMouseLeave={this.checkCreditCardCvvNumber}
                          onMouseDown={this.checkCreditCardCvvNumber}
                          onMouseMove={this.checkCreditCardCvvNumber}
                          // type="tel"
                          className="card-input__input"
                          maxLength="3"
                          autoComplete="off"
                          name="cardCvv"
                          onChange={this.handleFormChange}
                          onFocus={this.onCvvFocus}
                          onBlur={this.onCvvBlur}
                          ref={cardCvvRef}
                        />

                        {!isCreditCardCVVNumber ? (
                          <span
                            style={{
                              color: 'red',
                              fontWeight: 'bold',
                            }}
                          >
                            {creditCardCVVValidationMessage}
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-footer">
                <button
                  type="submit"
                  className="btn btn-outline-warning paybtn button mt-2 pay-now-btn"
                  onClick={this.submitPayment}
                  disabled={
                    loading || (cardType === cardTypes.QI_CARD && !isQiVerified)
                  }
                >
                  {loading && (
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: '5px' }}
                    />
                  )}

                  {loading && <span>{wait}</span>}
                  {!loading && <span> {payNowText} </span>}
                </button>

                <button
                  type="button"
                  className="btn paybtn btn-secondary mt-4"
                  onClick={this.cancelPayment}
                >
                  {cancel}
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default CForm;
