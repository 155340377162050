/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-const */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import {
  CSSTransition,
  TransitionGroup,
  SwitchTransition,
} from 'react-transition-group';
import './styles.scss';
import { cardTypes } from '../../../constants';

const CARDS = {
  visa: '^4',
  // amex: '^(34|37)',
  mastercard: '^5[1-5]',
  imarcan: '^3[1-5]',
  qi: '633',
  diners: '^300',
  // discover: '^6011',
  // unionpay: '^62',
  // troy: '^9792',
};

class Card extends Component {
  constructor(props) {
    super(props);

    // const backgroundImgname = this.cardBackgroundName();
    this.state = {
      style: {},
      // counter: 0,
      // backgroundImgname,
    };
  }

  componentDidUpdate(prevProps) {
    const { currentFocusedElm } = this.props;
    if (
      currentFocusedElm &&
      currentFocusedElm !== prevProps.currentFocusedElm
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ style: this.outlineElementStyle(currentFocusedElm) });
    }
  }

  cardBackgroundName = () => {
    const { cardType } = this.props;
    if (cardType === cardTypes.QI_CARD) {
      return `qi-1.png`;
      // return `qi-${Math.floor(Math.random() * 2 + 1)}.png`;
    }
    return `${Math.floor(Math.random() * 25 + 1)}.jpeg`;
  };

  /* removeEmptySpaces = cardNumber => {
    return cardNumber.replace(/\s+/g, '');
  };

  shouldMaskNumber = (cardNumber, index) => {
    if (cardNumber.length === 15) {
      return index > 4 && index < 12;
    } else {
      return index > 4 && index < 13;
    }
  }; */

  outlineElementStyle = element =>
    element
      ? {
          width: `${element.offsetWidth}px`,
          height: `${element.offsetHeight}px`,
          transform: `translateX(${element.offsetLeft}px) translateY(${element.offsetTop}px)`,
        }
      : null;

  cardType = () => {
    const { cardNumber: number, cardType } = this.props;
    if (cardType === cardTypes.QI_CARD) {
      return 'qi';
    }
    let re;
    for (const [card, pattern] of Object.entries(CARDS)) {
      re = new RegExp(pattern);
      if (number.match(re) != null) {
        // this.cardBackgroundName('qi');

        return card;
      }
    }

    return 'mastercard'; // default type
  };

  maskCardNumber(cardNumber) {
    const cardNumberArr = cardNumber.split('');
    cardNumberArr.forEach((val, index) => {
      if (index > 4 && index < 14) {
        if (cardNumberArr[index] !== ' ') {
          cardNumberArr[index] = '*';
        }
      }
    });

    return cardNumberArr;
  }

  render() {
    let {
      cardHolder,
      cardNumber,
      cardMonth,
      cardYear,
      // isd,
      cardCvv,
      isCardFlipped,
      currentFocusedElm,
      onCardElementClick,
      cardNumberRef,
      cardHolderRef,
      cardDateRef,
      cardType,
    } = this.props;

    const cardHolderArr = cardHolder.split('');
    const cardNumberArr = this.maskCardNumber(cardNumber);
    cardCvv = cardCvv.split('');

    return (
      <div
        className={`card-item ${
          isCardFlipped ? '-active' : ''
        } direction-enforced`}
      >
        <div className="card-item__side -front">
          <div
            className={`card-item__focus ${currentFocusedElm ? `-active` : ``}`}
            style={this.state.style}
          />
          <div className="card-item__cover">
            <img
              alt=""
              src={`${
                process.env.PUBLIC_URL
              }/assets/card-background/${this.cardBackgroundName()}`}
              className="card-item__bg"
            />
          </div>

          <div className="card-item__wrapper">
            <div
              className={`card-item__top ${
                cardType === cardTypes.MASTER_CARD ? '' : 'not-visible'
              }`}
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/chip.png`}
                alt=""
                className="card-item__chip"
              />
              <div className="card-item__type">
                <img
                  alt={this.cardType()}
                  src={`${
                    process.env.PUBLIC_URL
                  }/assets/card-type/${this.cardType()}.png`}
                  className="card-item__typeImg"
                />
              </div>
            </div>

            <label
              className="card-item__number"
              ref={cardNumberRef}
              onClick={() => onCardElementClick('cardNumber')}
            >
              <TransitionGroup className="slide-fade-up" component="div">
                {cardNumber ? (
                  cardNumberArr.map((val, index) => (
                    <CSSTransition
                      classNames="slide-fade-up"
                      timeout={250}
                      key={index}
                    >
                      <div className="card-item__numberItem">{val}</div>
                    </CSSTransition>
                  ))
                ) : (
                  <CSSTransition classNames="slide-fade-up" timeout={250}>
                    <div className="card-item__numberItem">#</div>
                  </CSSTransition>
                )}
              </TransitionGroup>
            </label>
            <div className="card-item__content">
              <label
                className="card-item__info"
                onClick={() => onCardElementClick('cardHolder')}
                ref={cardHolderRef}
              >
                <div className="card-item__holder">Card Holder</div>
                <div className="card-item__name">
                  <TransitionGroup className="slide-fade-up" component="div">
                    {cardHolder === 'FULL NAME' ? (
                      <CSSTransition classNames="slide-fade-up" timeout={250}>
                        <div>FULL NAME</div>
                      </CSSTransition>
                    ) : (
                      cardHolderArr.map((val, index) => (
                        <CSSTransition
                          timeout={250}
                          classNames="slide-fade-right"
                          key={index}
                        >
                          <span className="card-item__nameItem">{val}</span>
                        </CSSTransition>
                      ))
                    )}
                  </TransitionGroup>
                </div>
              </label>
              <div
                className="card-item__date"
                onClick={() => onCardElementClick('cardDate')}
                ref={cardDateRef}
              >
                <label className="card-item__dateTitle">Expires</label>
                <label className="card-item__dateItem">
                  <SwitchTransition in-out>
                    {!cardMonth ? (
                      <CSSTransition
                        classNames="slide-fade-up"
                        timeout={250}
                        key={cardMonth}
                      >
                        <span>MM</span>
                      </CSSTransition>
                    ) : (
                      <CSSTransition
                        classNames="slide-fade-up"
                        timeout={250}
                        key={cardMonth}
                      >
                        <span>{cardMonth}</span>
                      </CSSTransition>
                    )}
                  </SwitchTransition>
                </label>
                /
                <label htmlFor="cardYear" className="card-item__dateItem">
                  <SwitchTransition out-in>
                    {!cardYear ? (
                      <CSSTransition
                        classNames="slide-fade-up"
                        timeout={250}
                        key={cardYear}
                      >
                        <span>YY</span>
                      </CSSTransition>
                    ) : (
                      <CSSTransition
                        classNames="slide-fade-up"
                        timeout={250}
                        key={cardYear}
                      >
                        <span>{cardYear.toString().substr(-2)}</span>
                      </CSSTransition>
                    )}
                  </SwitchTransition>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="card-item__side -back">
          <div className="card-item__cover">
            <img
              alt={this.cardBackgroundName()}
              src={`${
                process.env.PUBLIC_URL
              }/assets/card-background/${this.cardBackgroundName()}`}
              className="card-item__bg"
            />
          </div>
          <div className="card-item__band" />
          <div className="card-item__cvv">
            <div className="card-item__cvvTitle">CVV</div>
            <div className="card-item__cvvBand">
              {cardCvv.map((val, index) => (
                <span key={val + index}>*</span>
              ))}
            </div>
            {/* {!this.state.iscriditcardnumber ?  
                       

                       <div className="card-item__cvvBand">
                       <input type="text" /> 
                   </div> 
                        : ''} */}
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
