/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import './SuccessPayment.scss';
import DOMPurify from 'dompurify';
import { getQueryParam } from '../../utils/utils';
import { tokenizeQiCard, tokenizeOtherCard } from '../../services/merchant';
import Toggle from '../Toggle/Toggle';
import { fmt } from '../LocaleWrapper/index';

const { sanitize } = DOMPurify;
const javascriptPurify = dirty => dirty.replace(/^(javascript\:)/, '');

class SuccessPayment extends Component {
  state = { proceedSave: false };

  redirectToSuccessUrl = () => {
    const { paymentData, cardData } = this.props;
    const { proceedSave } = this.state;
    if (proceedSave) {
      const token = getQueryParam('authToken');
      const isMasterCard = paymentData.method === 'MASTER_CARD';
      const tokenizeCard = !isMasterCard ? tokenizeQiCard : tokenizeOtherCard;
      tokenizeCard({ ...(isMasterCard ? {} : cardData), token }).then(
        ({ data }) => {
          setTimeout(() => {
            window.location.href = sanitize(
              javascriptPurify(
                `${paymentData.successUrl}&cardToken=${data.cardToken}`,
              ),
            );
          }, 1000);
        },
      );
    } else {
      window.location.href = sanitize(javascriptPurify(paymentData.successUrl));
    }
  };

  print = () => {
    window.print();
    return false;
  };

  toggleProceed = () => {
    const { proceedSave } = this.state;
    this.setState({ proceedSave: !proceedSave });
  };

  render() {
    const { proceedSave } = this.state;
    const { paymentData, isTokenizationAllowed } = this.props;
    const {
      cardNumber,
      paymentDate,
      method,
      orderId,
      amount,
      currency,
    } = paymentData;
    return (
      <div className="success-payment">
        <div className="alert alert-success">
          <div className="d-flex justify-content-start">
            {fmt(
              { id: 'success_page.alert_message' },
              { strong: chunks => <strong className="mx-1">{chunks}</strong> },
            )}
          </div>
        </div>
        <div>
          <section>
            <div className="container mt-5">
              <div className="row">
                <div className="col-lg-2" />
                <div className="col-lg-8">
                  <div className="card">
                    <div className="card-header card-header-success">
                      <div className="row justify-content-between mr-0 ml-0">
                        <div className="col-6 px-0 ml-2 d-flex justify-content-start">
                          <h4 className="font-weight-bolder pt-1 pb-0">
                            {fmt({ id: 'success_page.header' })}
                          </h4>
                        </div>
                        <div className="col-5 logo-sections justify-content-end pr-0 pl-0">
                          <img
                            src="../../assets/qi.png"
                            className="mx-1"
                            alt="QI"
                            height={20}
                            width={20}
                          />
                          <img
                            src="../../assets/mastercard.png"
                            alt="MasterCard"
                            height={30}
                            width={32}
                          />
                        </div>
                        <div className="col-md-4" />
                      </div>
                    </div>
                    <div className="card-body">
                      <form action="#">
                        <div className="row">
                          <div className="col-md-7">
                            <div className="d-flex justify-content-start my-2 align-items-start">
                              <span className="font-weight-bolder inputicon">
                                {fmt({ id: 'success_page.card_number' })}
                              </span>
                              <h6
                                className="text-break mb-0 px-2"
                                id="cardNumber"
                              >
                                {cardNumber}
                              </h6>
                            </div>
                            <div className="d-flex justify-content-start my-2 align-items-start">
                              <span className="font-weight-bolder inputicon">
                                {fmt({ id: 'success_page.payment_date' })}
                              </span>
                              <h6
                                className="text-break mb-0 px-2"
                                id="paymentDate"
                              >
                                {paymentDate}
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="d-flex justify-content-start my-2 align-items-start">
                              <span className="font-weight-bolder inputicon">
                                {fmt({ id: 'success_page.method' })}
                              </span>
                              <h6 className="text-break mb-0 px-2" id="method">
                                {method}
                              </h6>
                            </div>
                            <div className="d-flex justify-content-start my-2 align-items-start">
                              <span className="font-weight-bolder inputicon">
                                {fmt({ id: 'success_page.order_id' })}
                              </span>
                              <h6 className="text-break mb-0 px-2" id="orderId">
                                {orderId}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="row d-flex justify-content-start">
                          {isTokenizationAllowed && (
                            <div className="col-md-8 d-flex justify-content-start align-items-center">
                              <Toggle
                                onChange={this.toggleProceed}
                                id="proceed-save"
                                checked={proceedSave}
                                small
                              />
                              {fmt({ id: 'success_page.save_details' })}
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                    <div className="card-footer d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn btn-outline-warning button mr-2"
                      >
                        {fmt(
                          { id: 'success_page.payment_date' },
                          {
                            val: (
                              <span className="badge badge-danger ml-1">
                                {amount} {currency}
                              </span>
                            ),
                          },
                        )}
                      </button>
                      <button
                        type="button"
                        style={{
                          backgroundColor: '#f8ae01',
                        }}
                        className="btn btn-success float-right text-right"
                        onClick={this.redirectToSuccessUrl}
                      >
                        {fmt({ id: 'success_page.continue' })}
                      </button>
                    </div>
                    <div className="card-footer d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-success text-right"
                        onClick={this.print}
                      >
                        {fmt({ id: 'success_page.print' })}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="app-footer-container">
            <table className="app-footer m-3 position-absolute">
              <tbody>
                <tr>
                  <td>{fmt({ id: 'common.powered_by' })}</td>
                  <td className="footerImage mx-1">
                    <img
                      src="../../assets/qi.png"
                      alt="QI"
                      height={32}
                      width={32}
                    />
                    <img
                      src="../../assets/mastercard.png"
                      alt="MasterCard"
                      height={32}
                      width={32}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default SuccessPayment;
