/* eslint-disable no-useless-escape */
import React from 'react';
import './App.scss';
import swalWithReact from '@sweetalert/with-react';
import { BoxLoading } from 'react-loadingg';
import DOMPurify from 'dompurify';
import { fmt } from './components/LocaleWrapper/index';
import MainScreen from './components/Main';
import UnAuth from './components/Auth/UnAuthorized/UnAuthorized';
import { getQueryParam } from './utils/utils';
import { isValidToken } from './services/auth';
import SuccessPayment from './components/SuccessPayment/SuccessPayment';
import { SecureRedirection2 } from './components/3DSecureRedirect/3DSecureRedirect';

const { sanitize } = DOMPurify;
const javascriptPurify = dirty => dirty.replace(/^(javascript\:)/, '');

class App extends React.Component {
  timeoutId = 0;

  constructor(props) {
    super(props);
    this.state = {
      isValid: '',
      isAuthenticating: true,
      isSuccessfulPayment: false,
      paymentData: null,
      cardData: null,
      merchantData: null,
      // isValid: true,
      // isAuthenticating: false,
      // isSuccessfulPayment: false,
      // paymentData: {
      //   cardNumber: '1234123412341234',
      //   paymentDate: '12-10-2021',
      //   method: 'QI_CARD',
      //   orderId: '123123123123123123123',
      //   amount: '50',
      //   currency: 'USD',
      //   successUrl: `http://www.example.com`,
      // },
      // cardData: {
      //   cardNumber: '1231231231231234',
      //   cardHolder: '123123123123',
      // },
      // merchantData: {
      //   failureUrl: 'https://youtube.com',
      //   cancelUrl: 'https://example.com/cancel',
      //   isValid: true,
      //   amount: 110000,
      //   currency: 'USD',
      //   isAqsatiAllowed: true,
      //   isCardTokenizationAllowed: true,
      //   isMasterCardAllowed: true,
      //   isQICardAllowed: true,
      // },
    };
  }

  /**
   * handle failure message popup redirection
   * @param {*} error
   */
  handleFailureMessage = error => {
    swalWithReact({
      closeOnClickOutside: false,
      closeOnEsc: false,
      content: (
        <div>
          <b>{error.data?.error?.explanation || error.message || ''}</b>
          <br />
          <span>{error.data?.error?.cause || ''}</span>
        </div>
      ),
      icon: 'error',
      button: fmt({ id: 'common.ok' }),
      dangerMode: true,
    }).then(() => {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
      if (error.data?.failureUrl) {
        window.location.href = sanitize(
          javascriptPurify(error.data.failureUrl),
        );
      }
    });
    this.timeoutId = setTimeout(() => {
      window.location.href = sanitize(javascriptPurify(error.data.failureUrl));
    }, 60 * 1000);
  };

  /**
   * checkout auth token from BE
   * @returns {Promise<void>}
   */
  checkAuthToken = async () => {
    const authToken = getQueryParam('authToken') || null;
    try {
      const response = await isValidToken(authToken);
      this.setState({
        isAuthenticating: false,
        isValid: true,
        merchantData: response.data,
      });
    } catch (error) {
      this.setState({ isAuthenticating: false, isValid: false });
      if (error.data?.failureUrl) {
        this.handleFailureMessage(error);
        // window.location.href = sanitize(
        //   javascriptPurify(error.data?.failureUrl),
        // );
      } else {
        swalWithReact({
          closeOnClickOutside: false,
          closeOnEsc: false,
          content: <p>{error.message}</p>,
          icon: 'error',
          buttons: [fmt({ id: 'common.back' }), fmt({ id: 'common.ok' })],
          dangerMode: true,
        }).then(ok => {
          if (error.data?.failureUrl && ok) {
            window.location.href = sanitize(
              javascriptPurify(error.data?.failureUrl),
            );
          } else {
            window.history.back();
          }
        });
      }
    }
  };

  /**
   * check for online status and call the auth token check
   */
  checkAuthTokenValidity = () => {
    const { isAuthenticating } = this.state;
    if (isAuthenticating) {
      if (navigator.onLine) {
        this.checkAuthToken();
      } else {
        swalWithReact({
          closeOnClickOutside: false,
          closeOnEsc: false,
          content: <p>{fmt({ id: 'common.no_connection' })}</p>,
          // text: "Once deleted, you will not be able to recover this imaginary file!",
          icon: 'error',
          buttons: [fmt({ id: 'common.cancel' }), fmt({ id: 'common.ok' })],
          dangerMode: true,
        });
      }
    }
  };

  showSuccess = (paymentData, formData, cardData) => {
    this.setState({
      paymentData,
      isSuccessfulPayment: true,
      formData,
      cardData,
    });
  };

  render() {
    this.checkAuthTokenValidity();
    const {
      merchantData,
      isSuccessfulPayment,
      isValid,
      status,
      paymentData,
      formData,
      cardData,
    } = this.state;
    return (
      <>
        {isSuccessfulPayment ? (
          <>
            {paymentData ? (
              <SuccessPayment
                paymentData={paymentData}
                cardData={cardData}
                isTokenizationAllowed={
                  !!merchantData?.isCardTokenizationAllowed
                }
              />
            ) : (
              <SecureRedirection2 {...formData} />
            )}
          </>
        ) : (
          <div className="App">
            <div>
              {isValid ? (
                <MainScreen
                  merchantData={merchantData}
                  showSuccess={this.showSuccess}
                />
              ) : status === 'unauthorized' ? (
                <UnAuth />
              ) : (
                <BoxLoading />
              )}
            </div>
            <div className="app-footer-container">
              <table className="app-footer m-3">
                <tbody>
                  <tr>
                    <td>{fmt({ id: 'common.powered_by' })}</td>
                    <td className="footerImage mx-1">
                      <img
                        src="../../../assets/qi.png"
                        alt="QI"
                        height={32}
                        width={32}
                      />
                      <img
                        src="../../../assets/mastercard.png"
                        alt="MasterCard"
                        height={32}
                        width={32}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default App;
