import React from 'react';
import './Toggle.scss';

const ToggleSwitch = ({
  checked = false,
  ignoreDirection,
  onChange = () => {},
}) => {
  const toggleSwitch = e => {
    onChange(!e.target.checked);
  };

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className={`switch mb-0 ${ignoreDirection ? 'ignore-direction' : ''}`}
    >
      <input type="checkbox" onChange={toggleSwitch} checked={checked} />
      <span className="slider round" />
    </label>
  );
};

export default ToggleSwitch;
