import React, { useState, useRef } from 'react';
import CForm from './PaymentForm';
import Card from './PaymentCard';
import { cardTypes } from '../../constants';

const defaultCardNo = '#### #### #### ####';
const defaultCardHolderName = 'FULL NAME';
const defaultCardMonth = '';
const defaultCardYear = '';
const defaultCardCvv = '';

const MainScreen = ({ merchantData, showSuccess }) => {
  const initialState = {
    cardNumber: defaultCardNo,
    cardHolder: defaultCardHolderName,
    cardMonth: defaultCardMonth,
    cardYear: defaultCardYear,
    cardCvv: defaultCardCvv,
    cardType: cardTypes.MASTER_CARD,
    isCardFlipped: false,
    sp: 'block',
    currentFocusedElm: null,
  };
  const [state, setState] = useState(initialState);

  const updateStateValue = ({ name, value }) => {
    setState(prev => {
      return {
        ...prev,
        [name]: value || initialState[name],
      };
    });
  };

  const { currentFocusedElm } = state;

  // References for the Form Inputs
  const formFieldsRefObj = {
    cardNumber: useRef(),
    cardHolder: useRef(),
    cardDate: useRef(),
    cardCvv: useRef(),
  };

  const focusFormFieldByKey = () => {
    // formFieldsRefObj[key].current.focus();
  };

  const onCardElementClick = key => {
    focusFormFieldByKey(key);
  };

  // This are the references for the Card DIV elements
  const cardElementsRef = {
    cardNumber: null,
    cardHolder: null,
    cardDate: null,
  };

  const onCardFormInputFocus = (_event, inputName) => {
    setState({
      ...state,
      currentFocusedElm: cardElementsRef[inputName],
    });
  };

  const onCardInputBlur = () => {
    setState({
      ...state,
      currentFocusedElm: null,
    });
  };

  return (
    <div className="wrapper" style={{ display: state.sp }}>
      <CForm
        onUpdateStateValue={updateStateValue}
        showSuccess={showSuccess}
        cardNumberRef={formFieldsRefObj.cardNumber}
        cardHolderRef={formFieldsRefObj.cardHolder}
        cardDateRef={formFieldsRefObj.cardDate}
        onCardInputFocus={onCardFormInputFocus}
        onCardInputBlur={onCardInputBlur}
        merchantData={merchantData}
      >
        <Card
          cardNumber={state.cardNumber}
          cardHolder={state.cardHolder}
          cardMonth={state.cardMonth}
          cardYear={state.cardYear}
          cardCvv={state.cardCvv}
          cardType={state.cardType}
          isCardFlipped={state.isCardFlipped}
          currentFocusedElm={currentFocusedElm}
          onCardElementClick={onCardElementClick}
          cardNumberRef={node => {
            cardElementsRef.cardNumber = node;
          }}
          cardHolderRef={node => {
            cardElementsRef.cardHolder = node;
          }}
          cardDateRef={node => {
            cardElementsRef.cardDate = node;
          }}
        />
      </CForm>
    </div>
  );
};

export default MainScreen;
