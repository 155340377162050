import React from 'react';
import { fmt } from '../../LocaleWrapper';

const unAuth = () => {
  return (
    <div
      style={{
        backgroundColor: '#f0f0f2',
        margin: '0',
        padding: '0',
      }}
    >
      <div
        style={{
          width: '600px',
          margin: '5em auto',
          padding: ' 2em',
          borderRadius: '#fdfdff',
          borderradius: ' 0.5em',
          boxshadow: '2px 3px 7px 2px rgba(0,0,0,0.02)',
        }}
      >
        <h1>{fmt({ id: 'common.unauthorized' })}</h1>
        <p>
          {fmt(
            { id: 'common.unknown_error' },
            {
              val: <a href="support@qi.iq">support@qi.iq</a>,
              br: chunks => <b>{chunks}</b>,
            },
          )}
        </p>
      </div>
    </div>
  );
};

export default unAuth;
