import networkClient from '../utils/networkClient';

export const getMerchantData = () => {
  return Promise.resolve();
  // return networkClient.get(`/transactions/business/token`, undefined, headers);
};

export const proceedPayment = data => {
  return networkClient.post(`/transactions/business/mpgs/proceed`, data, {
    headers: {
      'x-auth-pay': process.env.REACT_APP_AUTH_PAY_KEY,
    },
  });
};

export const verifyQiCard = data => {
  return networkClient.post(`/transactions/business/qi/verify`, data, {
    headers: {
      'x-auth-pay': process.env.REACT_APP_AUTH_PAY_KEY,
    },
  });
};

export const confirmQiCard = data => {
  return networkClient.post(`/transactions/business/qi/confirm`, data, {
    headers: {
      'x-auth-pay': process.env.REACT_APP_AUTH_PAY_KEY,
    },
  });
};

export const tokenizeQiCard = data => {
  return networkClient.post(`/tokens/business/qi`, data, {
    headers: {
      'x-auth-pay': process.env.REACT_APP_AUTH_PAY_KEY,
    },
  });
};

export const tokenizeOtherCard = data => {
  return networkClient.post(`/tokens/mc`, data, {
    headers: {
      'x-auth-pay': process.env.REACT_APP_AUTH_PAY_KEY,
    },
  });
};

export const verifyQiInstallment = data => {
  return networkClient.post('/transactions/business/qi/aqsati/verify', data, {
    headers: {
      'x-auth-pay': process.env.REACT_APP_AUTH_PAY_KEY,
    },
  });
};

export const confirmQiInstallment = data => {
  return networkClient.post('/transactions/business/qi/aqsati/confirm', data, {
    headers: {
      'x-auth-pay': process.env.REACT_APP_AUTH_PAY_KEY,
    },
  });
};

export const proceedPaymentInstallment = data => {
  return networkClient.post(
    `/transactions/business/mpgs/aqsati/proceed`,
    data,
    {
      headers: {
        'x-auth-pay': process.env.REACT_APP_AUTH_PAY_KEY,
      },
    },
  );
};
