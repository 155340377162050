import React, { Component, createRef, useRef, useEffect } from 'react';
import { fmt } from '../LocaleWrapper/index';
import './3DSecureRedirect.scss';

export const SecureRedirection2 = ({ htmlContent }) => {
  const contentRef = useRef();

  useEffect(() => {
    window.addEventListener('message', function name(e) {
      console.dir(e);
    });
    setTimeout(() => {
      const fragment = document.createRange().createContextualFragment(
        `${htmlContent}`, // a.content is html string including script tag
      );
      if (contentRef.current) {
        contentRef.current.appendChild(fragment);

        // eslint-disable-next-line no-eval
        window.eval(document.getElementById('authenticate-payer-script').text);
      }
    }, 1000);

    return () => {
      window.removeEventListener('message');
    };
  }, []);
  return (
    <>
      {/* <div className="spinner-border text-warning" role="status">
        <span className="sr-only">{fmt({ id: 'common.loading' })}</span>
      </div> */}
      <div ref={contentRef} className="redirection" />
    </>
  );
};
class SecureRedirection extends Component {
  submitRef = createRef();

  componentDidMount() {
    setTimeout(() => {
      this.submitRef.current.submit();
    }, 1000);
  }

  render() {
    const { echoForm, PaReq, termUrl, md } = this.props;
    return (
      <>
        <form
          action={echoForm}
          ref={this.submitRef}
          method="POST"
          acceptCharset="UTF-8"
        >
          <input type="hidden" name="PaReq" value={PaReq} />
          <input type="hidden" name="TermUrl" value={termUrl} />
          <input type="hidden" name="MD" value={md} />
          <div className="msg">
            <div className="spinner-border text-warning" role="status">
              <span className="sr-only">{fmt({ id: 'common.loading' })}</span>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default SecureRedirection;
